<template>
  <div>
    <form ref="registerForm" @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('pro-register.register.form.shop-name')"
            label-for="shop-name-input"
            :invalid-feedback="$t('pro-register.register.validation.shop-name')"
            :state="fieldIsValid(newUser.shop.name)"
          >
            <b-form-input
              id="shop-name-input"
              v-model="newUser.shop.name"
              :state="fieldIsValid(newUser.shop.name)"
              required
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('register.form.phone')"
            label-for="phone-input"
            :invalid-feedback="$t('register.validation.phone')"
            :state="fieldIsValid(newUser.phone)"
          >
            <b-form-input id="phone-input" v-model="newUser.phone" :state="fieldIsValid(newUser.phone)" required />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('register.form.last-name')"
            label-for="last-name-input"
            :invalid-feedback="$t('register.validation.last-name')"
            :state="fieldIsValid(newUser.lastName)"
          >
            <b-form-input
              id="last-name-input"
              v-model="newUser.lastName"
              :state="fieldIsValid(newUser.lastName)"
              required
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('register.form.first-name')"
            label-for="first-name-input"
            :invalid-feedback="$t('register.validation.first-name')"
            :state="fieldIsValid(newUser.firstName)"
          >
            <b-form-input
              id="first-name-input"
              v-model="newUser.firstName"
              :state="fieldIsValid(newUser.firstName)"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('register.form.email')"
            label-for="email-input"
            :invalid-feedback="$t('register.validation.email')"
            :state="fieldIsValid(newUser.email) && emailIsValid(newUser.email)"
          >
            <b-form-input
              id="email-input"
              v-model="newUser.email"
              :state="fieldIsValid(newUser.email) && emailIsValid(newUser.email)"
              required
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('register.form.password')"
            label-for="password-input"
            :invalid-feedback="$t('register.validation.password')"
            :state="fieldIsValid(newUser.password)"
          >
            <b-input-group>
              <b-form-input
                id="password-input"
                :type="showPassword ? 'text' : 'password'"
                v-model="newUser.password"
                :state="fieldIsValid(newUser.password)"
                required
              />
              <b-input-group-append>
                <b-button @click="togglePassword" variant="dark">
                  <b-icon-eye-slash v-if="showPassword"></b-icon-eye-slash>
                  <b-icon-eye v-else></b-icon-eye>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('user.address.form.country')"
            label-for="address-country-input"
            :invalid-feedback="$t('user.address.validation.country')"
            :state="newUser.shop.address.country !== null ? newUser.shop.address.country.length > 0 : null"
          >
            <b-form-select
              id="address-country-input"
              v-model="newUser.shop.address.country"
              :options="countries"
              :state="newUser.shop.address.country !== null ? newUser.shop.address.country.length > 0 : null"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <b-form-group :label="$t('pro-register.register.form.facebook-page')" label-for="facebook-page-input">
            <b-form-input id="facebook-page-input" v-model="newUser.shop.configuration.facebookPageLink" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('pro-register.register.form.instagram-page')" label-for="instagram-page-input">
            <b-form-input id="instagram-page-input" v-model="newUser.shop.configuration.instagramPageLink" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group :invalid-feedback="$t('register.validation.cgv')" :state="fieldIsValid(cgvAccepted)">
        <b-form-checkbox
          v-model="cgvAccepted"
          name="cgv"
          value="accepted"
          unchecked-value="not-accepted"
          :required="true"
          :state="fieldIsValid(cgvAccepted)"
        >
          <span v-html="$t('register.form.cgv')"></span>
        </b-form-checkbox>
      </b-form-group>
      <div class="text-center mt-5">
        <b-button variant="dark" class="bg-orange" type="submit" :disabled="loading">
          <b-spinner small type="grow" v-if="loading" class="align-middle mr-2"></b-spinner>
          {{ $t("register.form.submit") }}
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/utils/api";
import Bugsnag from "@bugsnag/js";
import { mapActions } from "vuex";

export default {
  name: "ProRegister",
  data() {
    return {
      showPassword: false,
      loading: false,
      cgvAccepted: null,
      newUser: this.getUser(),
      countries: [
        { value: "AT", text: this.$t("user.address.form.countries.AT") },
        { value: "BE", text: this.$t("user.address.form.countries.BE") },
        { value: "BG", text: this.$t("user.address.form.countries.BG") },
        { value: "HR", text: this.$t("user.address.form.countries.HR") },
        { value: "CH", text: this.$t("user.address.form.countries.CH") },
        { value: "CY", text: this.$t("user.address.form.countries.CY") },
        { value: "CZ", text: this.$t("user.address.form.countries.CZ") },
        { value: "DK", text: this.$t("user.address.form.countries.DK") },
        { value: "EE", text: this.$t("user.address.form.countries.EE") },
        { value: "FI", text: this.$t("user.address.form.countries.FI") },
        { value: "FR", text: this.$t("user.address.form.countries.FR") },
        { value: "DE", text: this.$t("user.address.form.countries.DE") },
        { value: "GR", text: this.$t("user.address.form.countries.GR") },
        { value: "HU", text: this.$t("user.address.form.countries.HU") },
        { value: "IE", text: this.$t("user.address.form.countries.IE") },
        { value: "IT", text: this.$t("user.address.form.countries.IT") },
        { value: "LV", text: this.$t("user.address.form.countries.LV") },
        { value: "LT", text: this.$t("user.address.form.countries.LT") },
        { value: "LU", text: this.$t("user.address.form.countries.LU") },
        { value: "MT", text: this.$t("user.address.form.countries.MT") },
        { value: "NL", text: this.$t("user.address.form.countries.NL") },
        { value: "PL", text: this.$t("user.address.form.countries.PL") },
        { value: "PT", text: this.$t("user.address.form.countries.PT") },
        { value: "RO", text: this.$t("user.address.form.countries.RO") },
        { value: "SK", text: this.$t("user.address.form.countries.SK") },
        { value: "SI", text: this.$t("user.address.form.countries.SI") },
        { value: "ES", text: this.$t("user.address.form.countries.ES") },
        { value: "SE", text: this.$t("user.address.form.countries.SE") },
        { value: "US", text: this.$t("user.address.form.countries.US") },
      ],
    };
  },
  methods: {
    ...mapActions("authentication", ["setAuthenticationToken"]),
    getUser() {
      return {
        shop: {
          name: null,
          email: null,
          phone: null,
          address: {
            country: null,
          },
          configuration: {
            facebookPageLink: null,
            instagramPageLink: null,
          },
        },
        lastName: null,
        firstName: null,
        email: null,
        phone: null,
        password: null,
      };
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    fieldIsValid(value) {
      return value !== null ? value.length > 0 : null;
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    checkFormValidity() {
      const valid = this.$refs.registerForm.checkValidity();

      if (!valid) {
        this.newUser.shop.name = this.newUser.shop.name || "";
        this.newUser.lastName = this.newUser.lastName || "";
        this.newUser.firstName = this.newUser.firstName || "";
        this.newUser.email = this.newUser.email || "";
        this.newUser.phone = this.newUser.phone || "";
        // this.newUser.password = this.newUser.password || "";
        this.cgvAccepted = !!this.cgvAccepted;
      }

      return valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.loading = true;
      this.newUser.locale = this.$i18n.locale;
      this.newUser.shop.email = this.newUser.email;
      this.newUser.shop.phone = this.newUser.phone;

      return api
        .proRegister(this.newUser)
        .then(response => {
          if (response.status !== 201) {
            const error = new Error();
            error.error = response;
            error.error_code = response.status;

            throw error;
          }

          window.fbq("track", "CompleteRegistration");

          this.$bvModal.msgBoxOk(this.$t("pro-register.register.success"));
          this.newUser = this.getUser();
          this.cgvAccepted = null;
        })
        .catch(error => {
          if (error.response && error.response.status === 409) {
            this.$bvToast.toast(this.$t("register.email-exists.text"), {
              title: this.$t("register.email-exists.title"),
              variant: "danger",
              solid: true,
            });
            return;
          }

          if (error.message === "Network Error") {
            this.$bvToast.toast(this.$t("common.network-error.text"), {
              title: this.$t("common.network-error.title"),
              variant: "danger",
              solid: true,
            });
            return;
          }

          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(error));
            Bugsnag.notify(error);
          }
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
