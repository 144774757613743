<template>
  <div class="h-w-100">
    <b-navbar toggleable="lg" type="dark" variant="grey">
      <b-navbar-brand :to="{ name: 'MyShop' }"
        ><img src="/img/logov2_white.png" class="header-logo" alt="Logo Flixby"
      /></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <locale-switcher />

          <b-nav-item-dropdown v-if="loggedIn" right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em v-html="userInfo.name"></em>
            </template>
            <b-dropdown-item href="/logout">{{ $t("common.logout") }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-else exact-active-class="active" :to="{ name: 'Login' }">
            {{ $t("common.login") }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container id="pro-container" class="p-0" fluid>
      <!--<header>-->
      <!--<img id="header-img" src="/img/header.jpg" alt="Photo" />-->
      <!--</header>-->
      <section id="intro">
        <b-container class="p-5" fluid>
          <!--<h1 class="mb-5">{{ $t("pro-register.intro.title") }}</h1>-->
          <b-row align-v="center">
            <!--<b-col sm="6" class="pl-5">-->
            <!--<div ref="demo" data-plyr-provider="youtube" data-plyr-embed-id="cc4vfwANelI"></div>-->
            <!--</b-col>-->
            <!--<b-col sm="6" class="pl-5">-->
            <b-col sm="12">
              <!--<h1 class="mb-5">{{ $t("pro-register.register.title") }}</h1>-->
              <h1 class="mb-5">{{ $t("pro-register.intro.title") }}</h1>
              <pro-register></pro-register>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!--<section id="register">-->
      <!--<b-container class="p-5">-->
      <!--<h1 class="mb-5">{{ $t("pro-register.register.title") }}</h1>-->
      <!--<pro-register></pro-register>-->
      <!--</b-container>-->
      <!--</section>-->
      <footer class="bg-grey p-3">
        <Footer color="light"></Footer>
      </footer>
    </b-container>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";
import Footer from "@/components/Footer";
import { mapGetters } from "vuex";
// import Plyr from "plyr";
import ProRegister from "@/components/ProRegister";

export default {
  name: "ProRegisterPage",
  components: { ProRegister, LocaleSwitcher, Footer },
  computed: {
    ...mapGetters("authentication", ["loggedIn", "userInfo"]),
  },
  mounted() {
    // new Plyr(this.$refs.demo, {
    //   autoplay: true,
    //   loop: {
    //     active: true,
    //   },
    //   controls: ["play-large", "play", "progress", "current-time", "mute", "volume", "fullscreen"],
    // });
  },
};
</script>

<style lang="scss" scoped>
#pro-container {
  #header-img {
    width: 100%;
    object-fit: cover;
  }

  h1 {
    text-align: center;
    color: #333;
    font-size: 2rem;
    font-weight: bold;
  }

  #intro {
    height: calc(100vh - 80px - 56px);
    background-color: #eee;

    ul li:before {
      content: "✓";
      color: #f27b21;
    }

    #register-cta {
      background-color: #f27b21;
      border-color: #f27b21;
      padding: 20px 30px;
      border-radius: 20px;
      font-size: 1.2rem;
      font-weight: bold;
      margin: 20px 0;
    }
  }

  footer {
    color: white !important;

    a {
      color: white;
    }
  }
}
</style>
