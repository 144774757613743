<template>
  <b-nav-item-dropdown :text="currentLocale" right>
    <b-dropdown-item v-for="locale in locales" :key="locale" @click="setLocale(locale)">
      {{ $t(`locale.${locale}`) }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/utils/api";

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: ["fr", "nl", "en"],
    };
  },
  computed: {
    ...mapGetters("authentication", ["userInfo", "loggedIn"]),
    currentLocale() {
      return this.$i18n.locale.toUpperCase();
    },
  },
  methods: {
    setLocale(locale, updateServer = true) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);

      if (updateServer && this.loggedIn) {
        api.updateUserLocale(locale);
      }
    },
  },
  watch: {
    userInfo(userInfo) {
      if (!userInfo || !userInfo.locale) {
        return;
      }

      this.setLocale(userInfo.locale, false);
    },
  },
};
</script>
