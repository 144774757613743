import axios from "axios";
import AUTHENTICATION from "@/utils/constants/authentication";
import QueryString from "qs";

const existingToken = localStorage.getItem(AUTHENTICATION.TOKEN_KEY);
const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_URL}/api/`,
  headers: {
    common: {
      Authorization: existingToken ? `Bearer ${existingToken}` : "",
    },
  },
  paramsSerializer: params => QueryString.stringify(params),
});

// // response interceptor
// api.interceptors.response.use(
//   function(response) {
//     return response;
//   },
//   function(error) {
//     return Promise.reject(error);
//   }
// );

export default {
  setAuthorizationToken(token) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  clearAuthorizationToken() {
    apiClient.defaults.headers.common["Authorization"] = "";
  },
  login(username, password) {
    return apiClient.post(`login_check`, { username, password });
  },
  register(user) {
    return apiClient.post(`register`, user);
  },
  forgottenPasswordEmail(email) {
    return apiClient.post(`forgotten-password`, { email });
  },
  setNewPassword(token, password) {
    return apiClient.post(`forgotten-password`, { token, password });
  },
  proRegister(user) {
    return apiClient.post(`register-pro`, user);
  },
  facebookLogin(accessToken, locale, refererShop = null) {
    return apiClient.post(`facebook`, { accessToken, locale, refererShop });
  },
  sellerFacebookConnect(accessToken) {
    return apiClient.post(`shop/connect/facebook`, { accessToken });
  },
  sellerShopifyConnect(queryString) {
    return apiClient.post(`shop/connect/shopify?${queryString}`);
  },
  sellerPrestashopConnect(queryString) {
    return apiClient.post(`shop/connect/prestashop?${queryString}`);
  },
  downloadPrestashopZip() {
    return apiClient.get(`shop/download/prestashop`, { responseType: "blob" });
  },
  googleLogin(idToken, locale) {
    return apiClient.post(`google`, { idToken, locale });
  },

  /** Product Routes **/
  listCategories(page, limit, filters) {
    return apiClient.get(`categories`, {
      params: { page, limit, filters },
    });
  },
  listProducts(page, limit, filters) {
    return apiClient.get(`products`, {
      params: { page, limit, filters },
    });
  },
  refreshProducts() {
    return apiClient.get(`products/refresh`);
  },
  createProduct(product) {
    return apiClient.post(`products`, product);
  },
  getProduct(id) {
    return apiClient.get(`product/${id}`);
  },
  updateProduct(product) {
    return apiClient.put(`products/${product.id}`, product);
  },
  deleteProduct(product) {
    return apiClient.delete(`products/${product.id}`);
  },
  productNotifyMe(item) {
    return apiClient.post(`product/notify-me`, item);
  },

  /** Shop Routes **/
  getShop() {
    return apiClient.get(`shop`);
  },
  updateShop(shop) {
    return apiClient.put(`shop/${shop.id}`, shop);
  },
  getShopConfiguration() {
    return apiClient.get(`shop/configuration`);
  },
  getShopPaymentMethodsFees() {
    return apiClient.get(`shop/configuration/payment-methods-fees`);
  },
  getShopDeliveryMethods() {
    return apiClient.get(`shop/configuration/delivery-methods`);
  },
  getAppQrCode() {
    return apiClient.get(`qrcode`).then(response => response.data);
  },
  updateShopConfiguration(configuration) {
    return apiClient.put(`shop/configuration`, configuration);
  },
  getShopDashboard(liveId = null) {
    return apiClient.get(`shop/dashboard` + (liveId ? `/${liveId}` : ""));
  },

  /** Tools Routes **/
  getShopifyProductData(url) {
    return apiClient.get(`tools/shopify-product-data`, {
      params: { url },
    });
  },

  /** Live Routes **/
  listLives(page, limit, filters) {
    return apiClient.get(`lives`, {
      params: { page, limit, filters },
    });
  },
  createLive(live) {
    return apiClient.post(`lives`, live);
  },
  getLive(id) {
    return apiClient.get(`live/${id}`);
  },
  getLiveProductDetails(liveId, productId) {
    return apiClient.get(`live/${liveId}/product/${productId}`);
  },
  getLiveViewersCount(id) {
    return apiClient.get(`lives/${id}/viewers-count`);
  },
  random(liveId) {
    return apiClient.post(`lives/${liveId}/random`, {});
  },
  getLiveStats(id) {
    return apiClient.get(`lives/${id}/stats`);
  },
  updateLive(live) {
    return apiClient.put(`lives/${live.id}`, live);
  },
  updateLiveProductOrder(liveId, productsOrder) {
    return apiClient.patch(`lives/${liveId}/products-order`, { productsOrder });
  },
  updateLiveProduct(liveId, liveProductId, payload) {
    return apiClient.patch(`lives/${liveId}/products/${liveProductId}`, payload);
  },
  deleteLive(live) {
    return apiClient.delete(`lives/${live.id}`);
  },
  startLive(id) {
    return apiClient.post(`lives/${id}/start`);
  },
  startLiveRecording(id) {
    return apiClient.post(`lives/${id}/start-recording`);
  },
  stopLive(id) {
    return apiClient.post(`lives/${id}/stop`);
  },
  stopLiveRecording(id) {
    return apiClient.post(`lives/${id}/stop-recording`);
  },
  startLiveProduct(liveId, productId, startTime) {
    return apiClient.post(`lives/${liveId}/start-product/${productId}`, { startTime });
  },
  stopLiveProduct(liveId, productId, endTime) {
    return apiClient.post(`lives/${liveId}/stop-product/${productId}`, { endTime });
  },
  banUserFromLive(id, userId) {
    return apiClient.post(`lives/${id}/ban/${userId}`);
  },
  listLiveOrders(id, page, limit, filters) {
    filters = filters || {};
    filters.lives = [id];

    return apiClient.get(`orders`, {
      params: { page, limit, filters },
    });
  },
  exportOrders(liveId, filters) {
    filters = filters || {};
    if (liveId) {
      filters.lives = [liveId];
    }

    return apiClient.get(`orders/export`, {
      params: { filters },
      responseType: "blob",
    });
  },
  exportOrdersPdf(filters) {
    filters = filters || {};

    return apiClient.get(`orders/pdf`, {
      params: { filters },
      responseType: "blob",
    });
  },
  getOrderShippingPrice(orderId) {
    return apiClient.post(`orders/${orderId}/shipping-price`, {});
  },
  getOrderShippingParcelShopDetails(orderId) {
    return apiClient.get(`orders/${orderId}/parcel-shop`, {});
  },
  getParcelLabel(orderId, weight) {
    const data = {
      weight,
    };
    // append test relay point if is not in production mode
    if (process.env.NODE_ENV !== "production") {
      data["relayCode"] = "015891";
      data["relayCountry"] = "FR";
    }
    return apiClient.post(`orders/${orderId}/shipping`, data);
  },
  shared(liveId) {
    const liveKey = `live-${liveId}-shared`;
    const shared = localStorage.getItem(liveKey) === "1";

    if (shared) {
      return;
    }

    localStorage.setItem(liveKey, "1");
    return apiClient.post(`live/${liveId}/shared`);
  },
  liked(liveId) {
    return apiClient.post(`live/${liveId}/liked`);
  },
  remindMe(payload) {
    const liveKey = `live-${payload.live}-reminder`;
    const subscribed = localStorage.getItem(liveKey) === "1";

    if (subscribed) {
      return;
    }

    return apiClient.post(`live/${payload.live}/remind-me`, payload).then(response => {
      localStorage.setItem(liveKey, "1");
      return response;
    });
  },
  createMessage(liveId, message) {
    return apiClient.post(`live/${liveId}/message`, message);
  },
  getMessages(liveId) {
    return apiClient.get(`live/${liveId}/messages`);
  },
  getReplay(liveId) {
    return apiClient.get(`live/${liveId}/replay`);
  },
  getFacebookEmbed(liveId) {
    return apiClient.get(`live/${liveId}/facebook`);
  },
  listOrders(page, limit, filters) {
    filters = filters || {};

    return apiClient.get(`orders`, {
      params: { page, limit, filters },
    });
  },
  getOrder(id) {
    return apiClient.get(`orders/${id}`);
  },
  updateOrderStatus(id, status) {
    return apiClient.patch(`orders/${id}/status`, { status });
  },

  /** Stream Routes **/
  getHostToken(liveId) {
    return apiClient.post(`stream/${liveId}/host`);
  },
  getAudienceToken(liveId) {
    const liveKey = `live-${liveId}`;
    const countAsAnonymous = localStorage.getItem(liveKey) !== "1";
    localStorage.setItem(liveKey, "1");

    return apiClient.post(`stream/${liveId}/audience?c=${countAsAnonymous ? 1 : 0}`);
  },
  getChatToken(liveId) {
    return apiClient.post(`stream/${liveId}/chat`);
  },

  /** User Routes **/
  getHomeShops(page, limit) {
    return apiClient.get("home/shops", {
      params: { page, limit },
    });
  },
  userListOrders(page, limit, filters) {
    return apiClient.get(`order/list`, {
      params: { page, limit, filters },
    });
  },
  userGetOrder(id) {
    return apiClient.get(`order/${id}`);
  },

  /** Cart Routes **/
  getCart(liveId, cartId = null) {
    return apiClient.get(`live/${liveId}/cart` + (cartId ? `/${cartId}` : ""));
  },
  addItemToCart(liveId, item, cartId = null) {
    return apiClient.patch(`live/${liveId}/cart` + (cartId ? `/${cartId}` : ""), item);
  },
  updateItemQuantity(liveId, cartId, item) {
    return apiClient.patch(`live/${liveId}/cart/${cartId}/item/${item.id}`, { quantity: item.quantity });
  },
  deleteItemFromCart(liveId, cartId, item) {
    return apiClient.delete(`live/${liveId}/cart/${cartId}/item/${item.id}`);
  },
  updateCartAddress(liveId, cartId, address) {
    return apiClient.patch(`live/${liveId}/cart/${cartId}/address`, { addressId: address.id });
  },
  setCartUser(liveId, cartId) {
    return apiClient.patch(`live/${liveId}/cart/${cartId}/user`);
  },
  updateCartDeliveryAndPaymentMethods(liveId, cartId, deliveryMethod, paymentMethod, deliveryShop) {
    return apiClient.patch(`live/${liveId}/cart/${cartId}/delivery-payment-methods`, {
      deliveryMethod,
      paymentMethod,
      deliveryShop,
    });
  },
  updateCartComment(liveId, cartId, comment, hasVoucher) {
    return apiClient.patch(`live/${liveId}/cart/${cartId}/comment`, { comment, hasVoucher });
  },
  confirmCart(liveId, cartId) {
    return apiClient.post(`live/${liveId}/cart/${cartId}/confirm`);
  },
  unlockCart(liveId, cartId) {
    return apiClient.post(`live/${liveId}/cart/${cartId}/unlock`);
  },
  paidCart(liveId, cartId, paypalTransactionId = null) {
    return apiClient.post(`live/${liveId}/cart/${cartId}/paid`, { paypalTransactionId });
  },

  /** Address Routes **/
  createAddress(address) {
    return apiClient.post(`/user/address`, address);
  },
  updateAddress(address) {
    return apiClient.put(`/user/address/${address.id}`, address);
  },
  updateUserLocale(locale) {
    return apiClient.patch(`/profile/locale`, { locale });
  },

  /** Stripe Connect **/
  stripeConnect() {
    return apiClient.post(`stripe-connect`);
  },
  stripeConnectStatus() {
    return apiClient.get(`stripe-connect/status`);
  },
};
