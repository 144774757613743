<template>
  <b-container id="body" class="text-center">
    <b-row class="mt-5">
      <b-col sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4">
        <social-login></social-login>
      </b-col>
    </b-row>
    <hr />
    <p>
      <router-link :to="{ name: 'Register' }">{{ $t("home.body.register") }}</router-link>
      |
      <router-link :to="{ name: 'Login' }">{{ $t("common.login") }}</router-link>
    </p>
  </b-container>
</template>

<script>
import SocialLogin from "@/components/SocialLogin";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: { SocialLogin },
  computed: {
    ...mapGetters("authentication", ["loggedIn", "isSeller"]),
  },
  created() {
    this.redirectLoggedInUser();
  },
  methods: {
    redirectLoggedInUser() {
      if (this.loggedIn) {
        return this.$router.push({ name: this.isSeller ? "MyShop" : "UserHome" });
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
#body {
  a {
    color: #2e2e2d;
  }
}
</style>
