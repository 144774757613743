<template>
  <div id="app" class="h-w-100">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    meta: [{ vmid: "apple-itunes-app", name: "apple-itunes-app", content: "app-id=1606338835" }],
  },
  mounted() {
    console.log("App mounted");

    this.$store.dispatch("authentication/setTrackersUserId");
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: black;
}
</style>
