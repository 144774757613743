<template>
  <div :class="{ 'is-modal': isModal }">
    <template v-if="!isPro">
      <social-login :reload-on-login="reloadOnLogin" :is-modal="isModal"></social-login>
      <hr />
    </template>
    <b-form @submit.prevent="submit" class="text-center">
      <b-form-group>
        <b-form-input v-model="form.username" type="email" :placeholder="$t('login.email')" required></b-form-input>
      </b-form-group>
      <b-form-group class="text-right">
        <b-form-input
          v-model="form.password"
          type="password"
          :placeholder="$t('login.password')"
          required
        ></b-form-input>
        <a id="forgotten-password-modal-btn" href="javascript:void(0)" v-b-modal.modal-forgotten-password>
          {{ $t("login.forgotten-password.btn") }}
        </a>
      </b-form-group>
      <b-button
        v-if="isModal && !isPro"
        variant="secondary"
        id="register-modal-btn"
        href="javascript:void(0)"
        class="mr-4"
        v-b-modal.modal-register
      >
        {{ $t("home.body.register") }}
      </b-button>
      <b-button
        v-else
        variant="outline-dark"
        id="register-page-btn"
        :to="{ name: isPro ? 'ProRegister' : 'Register' }"
        class="mr-4"
      >
        {{ $t("home.body.register") }}
      </b-button>
      <b-button type="submit" :disabled="loading" variant="dark" class="bg-orange">
        <b-spinner small type="grow" v-if="loading" class="align-middle mr-2"></b-spinner>
        {{ $t("login.connect") }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SocialLogin from "@/components/SocialLogin";
import Bugsnag from "@bugsnag/js";

export default {
  name: "Login",
  components: { SocialLogin },
  props: {
    reloadOnLogin: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        username: "",
        password: "",
      },
      isPro: location.hostname === process.env.VUE_APP_PRO_DOMAIN,
    };
  },
  created() {
    this.redirectLoggedInUser();
  },
  computed: {
    ...mapGetters("authentication", ["loggedIn", "isSeller"]),
  },
  methods: {
    ...mapActions("authentication", ["login"]),
    submit() {
      this.loading = true;
      this.login(this.form)
        .then(() => {
          this.$bvToast.toast(this.$t("login.success.text"), {
            title: this.$t("login.success.title"),
            variant: "success",
            solid: true,
            autoHideDelay: 2000,
          });

          return this.redirectLoggedInUser();
        })
        .catch(e => {
          if (e.response && e.response.status === 401) {
            this.$bvToast.toast(e.response.data.message || this.$t("login.invalid-credentials.text"), {
              title: this.$t("login.invalid-credentials.title"),
              variant: "danger",
              solid: true,
            });

            return;
          }

          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(e);
          console.log(e.response);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(e));
            Bugsnag.notify(e);
          }
        })
        .then(() => (this.loading = false));
    },
    redirectLoggedInUser() {
      if (this.loggedIn) {
        if (this.reloadOnLogin && !this.isSeller) {
          return this.$router.go();
        }

        if (this.$route.query.target_path) {
          return this.$router.push(this.$route.query.target_path);
        }

        return this.$router.push({ name: this.isSeller ? "MyShop" : "UserHome" });
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
#register-modal-btn {
  color: white;
  &:hover {
    color: lightgray;
  }
}

#register-page-btn,
#forgotten-password-modal-btn {
  color: #2e2e2d;
  &:hover {
    color: darkgray;
  }
}

.is-modal {
  #register-page-btn,
  #forgotten-password-modal-btn {
    color: white;
    &:hover {
      color: lightgray;
    }
  }
}

#forgotten-password-modal-btn {
  font-size: 0.8em;
}
</style>
