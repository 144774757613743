<template>
  <b-container id="body" class="text-center mt-5">
    <b-row align-v="center">
      <b-col sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4">
        <login></login>
      </b-col>
    </b-row>
    <b-modal
      id="modal-forgotten-password"
      body-bg-variant="dark"
      body-text-variant="light"
      size="sm"
      hide-header
      hide-footer
      no-stacking
    >
      <forgotten-password
        v-if="$route.params.token"
        :reset-token="$route.params.token"
        v-on:reset-password-done="$bvModal.msgBoxOk($t('login.forgotten-password.done'))"
      ></forgotten-password>
      <forgotten-password-email
        v-else
        v-on:reset-password-email-sent="$bvModal.msgBoxOk($t('login.forgotten-password.email-sent'))"
      ></forgotten-password-email>
    </b-modal>
  </b-container>
</template>

<script>
import Login from "@/components/Login";
import ForgottenPassword from "@/components/ForgottenPassword";
import ForgottenPasswordEmail from "@/components/ForgottenPasswordEmail";

export default {
  name: "LoginPage",
  components: { Login, ForgottenPassword, ForgottenPasswordEmail },
  mounted() {
    if (this.$route.params.token) {
      this.$bvModal.show("modal-forgotten-password");
    }
  },
};
</script>
