<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="grey">
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <locale-switcher />

          <b-nav-item-dropdown v-if="loggedIn" right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em v-html="userInfo.name"></em>
            </template>
            <b-dropdown-item href="/logout">{{ $t("common.logout") }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-else exact-active-class="active" :to="{ name: 'Login' }">
            {{ $t("common.login") }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div id="header">
      <b-container>
        <b-row>
          <b-col sm="8" offset-sm="2" lg="6" offset-lg="3">
            <h1 class="text-center">
              <router-link :to="{ name: isPro ? 'ProRegister' : 'Home' }" class="position-relative">
                <img src="/img/logov2_white.png" class="header-logo" alt="Logo Flixby" />
              </router-link>
            </h1>
            <!--            <p class="text-orange mt-5" v-html="$t('home.header.text')"></p>-->
          </b-col>
        </b-row>
      </b-container>
    </div>
    <router-view />
    <Footer color="dark"></Footer>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";
import Footer from "@/components/Footer";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: { LocaleSwitcher, Footer },
  data() {
    return {
      isPro: location.hostname === process.env.VUE_APP_PRO_DOMAIN,
    };
  },
  computed: {
    ...mapGetters("authentication", ["loggedIn", "userInfo"]),
  },
};
</script>

<style lang="scss" scoped>
#header {
  background-color: #2e2e2d;
  color: white;
  padding: 5rem;

  h1 {
    position: relative;
    font-size: 4rem;

    a {
      text-decoration: none;
      color: white;
    }

    #subtitle {
      position: absolute;
      bottom: 3px;
      left: 5px;
      font-size: 6.5px;
    }
  }

  p {
    font-size: 0.8rem;
  }

  @media (max-width: 420px) {
    h1 {
      font-size: 3rem;

      #subtitle {
        bottom: 2px;
        left: 4px;
        font-size: 4.5px;
      }
    }
  }

  @media (max-width: 367px) {
    h1 {
      font-size: 2rem;

      #subtitle {
        bottom: -5px;
        left: 2px;
        font-size: 0.3rem;
      }
    }
  }
}

#footer {
  margin-top: 20px;
}
</style>
