import Vue from "vue";
import VueRouter from "vue-router";
import Public from "@/views/public/Public.vue";
import Home from "@/views/public/Home.vue";
import Login from "@/views/public/Login";
import Register from "@/views/public/Register";
import Pro from "@/views/public/Pro";
import store from "@/store";
import AUTHENTICATION from "@/utils/constants/authentication";
import Bugsnag from "@bugsnag/js";

Vue.use(VueRouter);

const homePath =
  location.hostname === process.env.VUE_APP_PRO_DOMAIN
    ? {
        path: "/",
        component: Pro,
        name: "ProRegister",
        meta: {
          allowAnonymous: true,
        },
      }
    : {
        path: "/",
        component: Public,
        meta: {
          allowAnonymous: true,
        },
        children: [
          {
            path: "",
            name: "Home",
            redirect: { name: "UserHome" },
            component: Home,
          },
          {
            path: "register",
            name: "Register",
            component: Register,
          },
        ],
      };

const routes = [
  homePath,
  {
    path: "/auth",
    component: Public,
    meta: {
      allowAnonymous: true,
    },
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
      {
        path: "/auth/:token",
        name: "Authenticate",
        redirect: to => {
          store.commit("authentication/clearAuthenticationToken");
          store.commit("clearCarts");

          store.commit("authentication/setAuthenticationToken", to.params.token);
          store.dispatch("authentication/setTrackersUserId");

          return { name: "Login" };
        },
      },
      {
        path: "/forgotten-password/:token",
        name: "ForgottenPassword",
        component: Login,
      },
      {
        path: "/logout",
        name: "Logout",
        redirect: () => {
          store.commit("authentication/clearAuthenticationToken");
          store.commit("clearCarts");

          return { name: "Login" };
        },
      },
    ],
  },
  {
    path: "/stream/:id",
    component: () => import(/* webpackChunkName: "user" */ "@/views/user/User.vue"),
    meta: {
      allowAnonymous: true,
    },
    children: [
      {
        path: "",
        name: "UserStream",
        component: () => import(/* webpackChunkName: "user" */ "@/views/common/Stream.vue"),
      },
      {
        path: "/home",
        name: "UserHome",
        beforeEnter() {
          document.location.href = "/home";
        },
        component: () => import(/* webpackChunkName: "user" */ "@/views/user/UserHome.vue"),
      },
    ],
  },
  {
    path: "/my-shop",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/Seller.vue"),
    meta: {
      allowedRoles: [AUTHENTICATION.ROLE.SELLER_ADMIN, AUTHENTICATION.ROLE.SELLER],
    },
    children: [
      {
        path: "",
        name: "MyShop",
        component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/MyShop.vue"),
      },
      {
        path: "configuration",
        name: "MyShop/Configuration",
        component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/Configuration.vue"),
        meta: {
          allowedRoles: [AUTHENTICATION.ROLE.SELLER_ADMIN],
        },
      },
      {
        path: "configuration/shopify",
        name: "MyShop/Configuration/Shopify",
        component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/configuration/Shopify.vue"),
        meta: {
          allowedRoles: [AUTHENTICATION.ROLE.SELLER_ADMIN],
        },
      },
      {
        path: "configuration/prestashop",
        name: "MyShop/Configuration/Prestashop",
        component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/configuration/Prestashop.vue"),
        meta: {
          allowedRoles: [AUTHENTICATION.ROLE.SELLER_ADMIN],
        },
      },
      {
        path: "products",
        name: "MyShop/Products",
        component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/Products.vue"),
      },
      {
        path: "products/:id",
        name: "MyShop/Product",
        component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/Product.vue"),
      },
      {
        path: "lives",
        name: "MyShop/Lives",
        component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/Lives.vue"),
      },
      {
        path: "lives/:id",
        name: "MyShop/Live",
        component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/Live.vue"),
      },
      {
        path: "orders",
        name: "MyShop/Orders",
        component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/Orders.vue"),
      },
      {
        path: "orders/:id/:liveId?",
        name: "MyShop/Order",
        component: () => import(/* webpackChunkName: "seller" */ "@/views/seller/Order.vue"),
      },
    ],
  },
  {
    path: "/user",
    component: () => import(/* webpackChunkName: "user" */ "@/views/user/User.vue"),
    meta: {
      allowedRoles: [AUTHENTICATION.ROLE.USER],
    },
    children: [
      {
        path: "audience",
        name: "Audience",
        component: () => import(/* webpackChunkName: "user" */ "@/views/user/Audience.vue"),
      },
      {
        path: "/orders",
        name: "User/Orders",
        component: () => import(/* webpackChunkName: "user" */ "@/views/user/Orders.vue"),
      },
      {
        path: "/orders/:id",
        name: "User/Order",
        component: () => import(/* webpackChunkName: "user" */ "@/views/user/Order.vue"),
      },
    ],
  },
  {
    path: "*",
    component: Public,
    meta: {
      allowAnonymous: true,
    },
    children: [
      {
        path: "",
        name: "NotFound",
        component: () => import(/* webpackChunkName: "not-found" */ "@/views/public/NotFound.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.onError(error => {
  //force reload page after update
  if (/loading.*chunk.*failed/i.test(error.message) && navigator.onLine) {
    window.location.reload();
  } else if (process.env.NODE_ENV === "production") {
    Bugsnag.leaveBreadcrumb(JSON.stringify(error));
    Bugsnag.notify(error);
  }
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some(m => m.meta.allowAnonymous) && !store.getters["authentication/loggedIn"]) {
    next({ name: "Login", query: { target_path: to.fullPath } });
    return;
  }

  const allowedRoles = to.matched.find(m => m.meta.allowedRoles !== undefined)?.meta?.allowedRoles;
  if (
    allowedRoles !== undefined &&
    !store.getters["authentication/userInfo"]?.roles.some(role => allowedRoles.includes(role))
  ) {
    next({ name: "Logout" });
    return;
  }

  next();
});

router.afterEach(() => {
  window.fbq("track", "PageView");
});

export default router;
