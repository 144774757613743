import Vue from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import VueGtag from "vue-gtag";
import "uikit";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vueAwesomeCountdown from "vue-awesome-countdown";
import VueMeta from "vue-meta";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./styles/main.scss";

if (process.env.NODE_ENV === "production") {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG,
    plugins: [new BugsnagPluginVue()],
    appVersion: process.env.VUE_APP_VERSION,
    onError: function(event) {
      if (!event.originalError) {
        return;
      }

      event.addMetadata("originalError", {
        content: JSON.stringify(event.originalError),
      });

      if (/(timeout|Network Error)/i.test(event.originalError.message)) {
        return false;
      }

      if (event.originalError.isTrusted !== undefined) {
        let ignore = false;
        event.errors[0].stacktrace.forEach(function(frame) {
          if (/\/plyr\//.test(frame.file)) {
            ignore = true;
          }
        });

        if (ignore) {
          return false;
        }
      }
    },
  });

  const bugsnagVue = Bugsnag.getPlugin("vue");
  bugsnagVue.installVueErrorHandler(Vue);

  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GA },
      pageTrackerTemplate(to) {
        return {
          page_title: to.name + (to.params.id ? `-${to.params.id}` : ""),
          page_path: to.path,
          page_location: location.href,
        };
      },
    },
    router
  );
}

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(vueAwesomeCountdown, "vac");
Vue.use(VueMeta);

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");
