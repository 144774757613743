<template>
  <div class="d-flex flex-column">
    <v-facebook-login
      :app-id="fbId"
      :login-options="{ scope }"
      v-model="facebook"
      @sdk-init="onSDKInit"
      @login="onFacebookLogin"
    ></v-facebook-login>
  </div>
</template>

<script>
import VFacebookLogin from "vue-facebook-login-component";
import api from "@/utils/api";
import Bugsnag from "@bugsnag/js";
import { mapActions, mapState } from "vuex";

export default {
  name: "SocialLogin",
  components: { VFacebookLogin },
  props: {
    reloadOnLogin: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["currentShop"]),
  },
  data() {
    return {
      fbId: process.env.VUE_APP_FACEBOOK_ID,
      scope:
        process.env.NODE_ENV === "production"
          ? "email"
          : "email,user_birthday,user_gender,user_location,user_friends,user_likes",
      facebook: null,
      loading: false,
      FB: null,
    };
  },
  methods: {
    ...mapActions("authentication", ["setAuthenticationToken"]),
    onSDKInit({ FB }) {
      this.FB = FB;
      this.FB.getLoginStatus(response => {
        if (response.authResponse && response.authResponse.accessToken) {
          this.FB.logout();
        }
      });
    },
    onFacebookLogin(response) {
      if (!response || !response.authResponse) {
        return;
      }

      return this.handleSocialLogin(
        api.facebookLogin(
          response.authResponse.accessToken,
          this.$i18n.locale,
          this.currentShop ? this.currentShop.id : null
        )
      );
    },
    handleSocialLogin(promise) {
      this.loading = true;

      return promise
        .then(response => {
          if (!response.data.token) {
            const error = new Error();
            error.error = response;
            error.error_code = response.status;

            throw error;
          }

          return this.setAuthenticationToken(response.data.token);
        })
        .then(() => {
          if (this.reloadOnLogin) {
            return this.$router.go();
          }

          if (this.$route.query.target_path) {
            return this.$router.push(this.$route.query.target_path);
          }

          return this.$router.push({ name: "UserHome" });
        })
        .catch(error => {
          if (error.response && error.response.status === 409) {
            this.$bvToast.toast(this.$t("register.email-exists.text"), {
              title: this.$t("register.email-exists.title"),
              variant: "danger",
              solid: true,
            });
            return;
          }

          if (error.message === "Network Error") {
            this.$bvToast.toast(this.$t("common.network-error.text"), {
              title: this.$t("common.network-error.title"),
              variant: "danger",
              solid: true,
            });
            return;
          }

          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(error));
            Bugsnag.notify(error);
          }
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.v-facebook-login {
  cursor: pointer;
  display: inline-block;
}
</style>
