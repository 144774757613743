<template>
  <div>
    <form ref="registerForm" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :label="$t('login.forgotten-password.email-field')"
        label-for="email-input"
        :invalid-feedback="$t('register.validation.email')"
        :state="fieldIsValid(email) && emailIsValid(email)"
      >
        <b-form-input id="email-input" v-model="email" :state="fieldIsValid(email) && emailIsValid(email)" required />
      </b-form-group>
      <div class="text-right mt-5">
        <b-button variant="dark" class="bg-orange" type="submit" :disabled="loading">
          <b-spinner small type="grow" v-if="loading" class="align-middle mr-2"></b-spinner>
          {{ $t("common.ok-btn") }}
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/utils/api";
import Bugsnag from "@bugsnag/js";

export default {
  name: "ForgottenPasswordEmail",
  data() {
    return {
      loading: false,
      email: null,
    };
  },
  methods: {
    fieldIsValid(value) {
      return value !== null ? value.length > 0 : null;
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    checkFormValidity() {
      const valid = this.$refs.registerForm.checkValidity();

      if (!valid) {
        this.email = this.email || "";
      }

      return valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.loading = true;

      return api
        .forgottenPasswordEmail(this.email)
        .then(() => {
          this.$emit("reset-password-email-sent");
          this.$bvModal.hide("modal-forgotten-password");
        })
        .catch(error => {
          if (error.message === "Network Error") {
            this.$bvToast.toast(this.$t("common.network-error.text"), {
              title: this.$t("common.network-error.title"),
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast(this.$t("common.error.text"), {
              title: this.$t("common.error.title"),
              variant: "danger",
              solid: true,
            });
          }

          console.log(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(error));
            Bugsnag.notify(error);
          }
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
