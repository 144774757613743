<template>
  <div id="footer" class="text-center">
    <span>{{ $t("footer.question") }}</span>
    <a id="contact-us" :class="{ dark: color === 'dark', light: color !== 'dark' }" :href="email">
      {{ $t("footer.contact-us") }}
    </a>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    color: String,
    default() {
      return "dark";
    },
  },
  data() {
    return {
      email: "mailto:hello@flixby.live",
    };
  },
};
</script>

<style lang="scss" scoped>
#contact-us {
  display: block;
  font-weight: bold;

  &.dark {
    color: #2e2e2d;
  }

  &.light {
    color: #fff;
  }
}
</style>
