import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

let browserLocale = navigator.language || navigator.userLanguage;
if (browserLocale) {
  browserLocale = browserLocale.split("-")[0];
}

let queryLocale = null;
if (location.search.indexOf("l=fr") !== -1) {
  queryLocale = "fr";
} else if (location.search.indexOf("l=nl") !== -1) {
  queryLocale = "nl";
} else if (location.search.indexOf("l=en") !== -1) {
  queryLocale = "en";
}

const i18n = new VueI18n({
  locale: queryLocale || localStorage.getItem("locale") || browserLocale || process.env.VUE_APP_I18N_LOCALE || "fr",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
  messages: loadLocaleMessages(),
});

export default i18n;
