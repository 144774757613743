import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/authentication";

Vue.use(Vuex);

const CARTS_KEY = "user_carts";

export default new Vuex.Store({
  state: {
    currentShop: null,
    currentLive: null,
    currentCart: null,
    onlivesListView: false,
    carts: localStorage.getItem(CARTS_KEY) !== null ? JSON.parse(localStorage.getItem(CARTS_KEY)) : {},
  },
  mutations: {
    setCurrentShop(state, shop) {
      state.currentShop = shop;
    },
    setCurrentLive(state, live) {
      state.currentLive = live;
      state.currentShop = live ? live.shop : null;
    },
    setCurrentCart(state, payload) {
      state.carts[payload.liveId] = payload.cart;
      state.currentCart = payload.cart;
      localStorage.setItem(CARTS_KEY, JSON.stringify(state.carts));
    },
    removeCart(state, liveId) {
      delete state.carts[liveId];
      localStorage.setItem(CARTS_KEY, JSON.stringify(state.carts));
    },
    clearCarts(state) {
      state.carts = {};
      localStorage.removeItem(CARTS_KEY);
    },
    setOnLivesListView(state, onlivesListView) {
      state.onlivesListView = onlivesListView;
    },
  },
  actions: {},
  getters: {},
  modules: {
    authentication,
  },
});
