import decode from "jwt-decode";
import AUTHENTICATION from "@/utils/constants/authentication";
import api from "@/utils/api";
import Vue from "vue";
import Bugsnag from "@bugsnag/js";

export default {
  namespaced: true,
  state: () => ({
    authenticationToken: localStorage.getItem(AUTHENTICATION.TOKEN_KEY),
  }),
  mutations: {
    setAuthenticationToken(state, token) {
      state.authenticationToken = token;
      api.setAuthorizationToken(token);
      localStorage.setItem(AUTHENTICATION.TOKEN_KEY, token);
    },
    clearAuthenticationToken(state) {
      state.authenticationToken = null;
      api.clearAuthorizationToken();
      localStorage.removeItem(AUTHENTICATION.TOKEN_KEY);
    },
  },
  actions: {
    login({ dispatch }, { username, password }) {
      return api.login(username, password).then(response => {
        if (!response.data.token) {
          const error = new Error();
          error.error = response;
          error.error_code = response.status;

          throw error;
        }

        return dispatch("setAuthenticationToken", response.data.token);
      });
    },
    async setAuthenticationToken({ dispatch, commit, rootState }, token) {
      commit("setAuthenticationToken", token);
      dispatch("setTrackersUserId");

      const carts = Object.entries(rootState.carts);
      console.log(carts);
      if (carts.length > 0) {
        for (const [liveId, cart] of carts) {
          if (cart.id) {
            await api.setCartUser(liveId, cart.id);
          }
        }
      }
    },
    setTrackersUserId({ getters }) {
      if (process.env.NODE_ENV !== "production" || !getters.loggedIn) {
        return;
      }

      const userInfo = getters.userInfo;

      Bugsnag.setUser(userInfo.id);
      Vue.$gtag.config({ user_id: userInfo.id });
    },
  },
  getters: {
    loggedIn: (state, getters) => !!state.authenticationToken && !getters.tokenExpired,
    userInfo: state => (state.authenticationToken ? decode(state.authenticationToken) : null),
    isFlixby: (_, getters) => getters.loggedIn && getters.userInfo.isFlixby,
    isSeller: (_, getters) =>
      getters.loggedIn &&
      (getters.userInfo.roles.indexOf(AUTHENTICATION.ROLE.SELLER_ADMIN) !== -1 ||
        getters.userInfo.roles.indexOf(AUTHENTICATION.ROLE.SELLER) !== -1),
    isSellerAdmin: (_, getters) =>
      getters.loggedIn && getters.userInfo.roles.indexOf(AUTHENTICATION.ROLE.SELLER_ADMIN) !== -1,
    isSellerQr: (_, getters) =>
      getters.loggedIn && getters.userInfo.roles.indexOf(AUTHENTICATION.ROLE.SELLER_QR) !== -1,
    tokenExpirationDate(state) {
      const token = decode(state.authenticationToken);
      if (!token.exp) {
        return null;
      }

      const date = new Date(0);
      date.setUTCSeconds(token.exp);

      return date;
    },
    tokenExpired(_, getters) {
      return getters.tokenExpirationDate < new Date();
    },
  },
};
