<template>
  <div>
    <form ref="registerForm" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :label="$t('register.form.password')"
        label-for="password-input"
        :invalid-feedback="$t('register.validation.password')"
        :state="fieldIsValid(password)"
      >
        <b-input-group>
          <b-form-input
            id="password-input"
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            :state="fieldIsValid(password)"
            required
          />
          <b-input-group-append>
            <b-button @click="togglePassword" variant="outline-light">
              <b-icon-eye-slash v-if="showPassword"></b-icon-eye-slash>
              <b-icon-eye v-else></b-icon-eye>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div class="text-center mt-5">
        <b-button variant="dark" class="bg-orange" type="submit" :disabled="loading">
          <b-spinner small type="grow" v-if="loading" class="align-middle mr-2"></b-spinner>
          {{ $t("common.save-btn") }}
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/utils/api";
import Bugsnag from "@bugsnag/js";

export default {
  name: "ForgottenPasswordEmail",
  props: {
    resetToken: String,
  },
  data() {
    return {
      loading: false,
      password: null,
      showPassword: false,
    };
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    fieldIsValid(value) {
      return value !== null ? value.length > 0 : null;
    },
    checkFormValidity() {
      const valid = this.$refs.registerForm.checkValidity();

      if (!valid) {
        this.email = this.email || "";
      }

      return valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.loading = true;

      return api
        .setNewPassword(this.resetToken, this.password)
        .then(() => {
          this.$emit("reset-password-done");
          this.$bvModal.hide("modal-forgotten-password");
        })
        .catch(error => {
          if (error.message === "Network Error") {
            this.$bvToast.toast(this.$t("common.network-error.text"), {
              title: this.$t("common.network-error.title"),
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast(this.$t("common.error.text"), {
              title: this.$t("common.error.title"),
              variant: "danger",
              solid: true,
            });
          }

          console.log(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(error));
            Bugsnag.notify(error);
          }
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
