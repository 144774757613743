<template>
  <b-container id="body" class="mt-5">
    <h1 class="mb-5 text-center">{{ $t("register.title") }}</h1>
    <b-row align-v="center">
      <b-col sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4">
        <register></register>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Register from "@/components/Register";

export default {
  name: "RegisterPage",
  components: { Register },
};
</script>
