<template>
  <div>
    <template v-if="!isPro">
      <social-login :reload-on-login="reloadOnLogin" :is-modal="isModal"></social-login>
      <hr />
    </template>
    <form ref="registerForm" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :label="$t('register.form.last-name')"
        label-for="last-name-input"
        :invalid-feedback="$t('register.validation.last-name')"
        :state="fieldIsValid(newUser.lastName)"
      >
        <b-form-input
          id="last-name-input"
          v-model="newUser.lastName"
          :state="fieldIsValid(newUser.lastName)"
          required
        />
      </b-form-group>
      <b-form-group
        :label="$t('register.form.first-name')"
        label-for="first-name-input"
        :invalid-feedback="$t('register.validation.first-name')"
        :state="fieldIsValid(newUser.firstName)"
      >
        <b-form-input
          id="first-name-input"
          v-model="newUser.firstName"
          :state="fieldIsValid(newUser.firstName)"
          required
        />
      </b-form-group>
      <b-form-group
        :label="$t('register.form.email')"
        label-for="email-input"
        :invalid-feedback="$t('register.validation.email')"
        :state="fieldIsValid(newUser.email) && emailIsValid(newUser.email)"
      >
        <b-form-input
          id="email-input"
          v-model="newUser.email"
          :state="fieldIsValid(newUser.email) && emailIsValid(newUser.email)"
          required
        />
      </b-form-group>
      <b-form-group
        :label="$t('register.form.password')"
        label-for="password-input"
        :invalid-feedback="$t('register.validation.password')"
        :state="fieldIsValid(newUser.password)"
      >
        <b-input-group>
          <b-form-input
            id="password-input"
            :type="showPassword ? 'text' : 'password'"
            v-model="newUser.password"
            :state="fieldIsValid(newUser.password)"
            required
          />
          <b-input-group-append>
            <b-button @click="togglePassword" :variant="isModal ? 'outline-light' : 'dark'">
              <b-icon-eye-slash v-if="showPassword"></b-icon-eye-slash>
              <b-icon-eye v-else></b-icon-eye>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group :invalid-feedback="$t('register.validation.privacy')" :state="fieldIsValid(privacyAccepted)">
        <b-form-checkbox
          v-model="privacyAccepted"
          name="privacy"
          value="accepted"
          unchecked-value="not-accepted"
          :required="true"
          :state="fieldIsValid(privacyAccepted)"
        >
          <span v-html="$t('register.form.privacy')"></span>
        </b-form-checkbox>
      </b-form-group>
      <div class="text-center mt-5">
        <b-button variant="dark" class="bg-orange" type="submit" :disabled="loading">
          <b-spinner small type="grow" v-if="loading" class="align-middle mr-2"></b-spinner>
          {{ $t("register.form.submit") }}
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/utils/api";
import Bugsnag from "@bugsnag/js";
import { mapActions, mapState } from "vuex";
import SocialLogin from "@/components/SocialLogin";

export default {
  name: "Register",
  components: { SocialLogin },
  props: {
    reloadOnLogin: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["currentShop"]),
  },
  data() {
    return {
      showPassword: false,
      loading: false,
      privacyAccepted: null,
      newUser: {
        lastName: null,
        firstName: null,
        email: null,
        password: null,
      },
      isPro: location.hostname === process.env.VUE_APP_PRO_DOMAIN,
    };
  },
  methods: {
    ...mapActions("authentication", ["setAuthenticationToken"]),
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    fieldIsValid(value) {
      return value !== null ? value.length > 0 : null;
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    checkFormValidity() {
      const valid = this.$refs.registerForm.checkValidity();

      if (!valid) {
        this.newUser.lastName = this.newUser.lastName || "";
        this.newUser.firstName = this.newUser.firstName || "";
        this.newUser.email = this.newUser.email || "";
        this.newUser.password = this.newUser.password || "";
        this.privacyAccepted = !!this.privacyAccepted;
      }

      return valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.loading = true;
      this.newUser.locale = this.$i18n.locale;

      if (this.currentShop) {
        this.newUser.refererShop = this.currentShop;
      }

      return api
        .register(this.newUser)
        .then(response => {
          if (response.status !== 201 || !response.data.token) {
            const error = new Error();
            error.error = response;
            error.error_code = response.status;

            throw error;
          }

          window.fbq("track", "CompleteRegistration");

          return this.setAuthenticationToken(response.data.token);
        })
        .then(() => {
          if (this.reloadOnLogin) {
            return this.$router.go();
          }

          return this.$router.push({ name: "UserHome" });
        })
        .catch(error => {
          if (error.response && error.response.status === 409) {
            this.$bvToast.toast(this.$t("register.email-exists.text"), {
              title: this.$t("register.email-exists.title"),
              variant: "danger",
              solid: true,
            });
            return;
          }

          if (error.message === "Network Error") {
            this.$bvToast.toast(this.$t("common.network-error.text"), {
              title: this.$t("common.network-error.title"),
              variant: "danger",
              solid: true,
            });
            return;
          }

          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(error));
            Bugsnag.notify(error);
          }
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
